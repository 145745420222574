import React, { useState, useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { useDispatch } from "react-redux"
// core components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import Button from "components/CustomButtons/Button.js";
import CloseIcon from '@material-ui/icons/Close'
import { CustomCropStyled } from "./CustomCrop.js"
import { uploadPicture } from 'actions/pictureUpload';
import {resizeImageFile} from './ImageResizer'

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";
import { showAlert } from "actions/alert.js";


export default function ImageUpload(props) {

  const { avatar, addButtonProps, changeButtonProps, removeButtonProps, onPictureChange, currentPictureUrl, aspectRatio, localImageName, onPictureRemove, keyNamePrefix="", } = props;

  const dispatch=useDispatch();
  const uploadPictureAction= async(pictureBlob) =>  dispatch(uploadPicture(pictureBlob));

  const defaultPreviewImage = props?.currentPictureUrl ? currentPictureUrl : avatar ? defaultAvatar : defaultImage;
  const [selectedImage, setSelectedImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultPreviewImage);
  const [showCropModal, setShowCropModal] = useState(false)
  const [showFileSizeError, setShowFileSizeError] = useState(false)
  const [pictureLoading, setPictureLoading] = useState(false)

  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedImageFile(reader.result);
      setShowCropModal(true);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    e.target.value = '';
  };
  
  // eslint-disable-next-line
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setSelectedImageFile(null);
    const defaultPreviewImage = props?.currentPictureUrl ? currentPictureUrl : avatar ? defaultAvatar : defaultImage;
    setImagePreviewUrl(defaultPreviewImage);
    fileInput.current.value = null;
    if(onPictureRemove)
      onPictureRemove(null)
  };
  const uploadCroppedImage = async (croppedFile = selectedImage) => {
    let data = new FormData()
    if (croppedFile.size > (1048576*2)) {
      console.log("Iniitial cropped image file size is very large. So resized image to a max width/height of 800px")
      const maxWidth =  aspectRatio ? parseInt(1080*aspectRatio): 800; 
      const maxHeight =  800; 
      croppedFile = await resizeImageFile(croppedFile,{quality:60,maxWidth,maxHeight});
      //console.log(croppedFile)
    }
    if (croppedFile.size > (1048576*2)) {
      setShowFileSizeError(true);
      dispatch(showAlert("error", "File size is very large. Try a different image"))
      return false;
    } else {
      data.append("file", croppedFile)
      data.append("keyNamePrefix", keyNamePrefix)
      const uploadedPictureData = await uploadPictureAction(data)
      if(uploadedPictureData){
        setImagePreviewUrl(uploadedPictureData.fileUrl)
        setSelectedImageFile(croppedFile)
        localStorage.setItem(localImageName, JSON.stringify(uploadedPictureData))
        return uploadedPictureData;
      }else{
        return false
      }
    }
  }
  const handleCropDialog = () => {
    setShowCropModal(!showCropModal)
  }
  const onConfirmCrop = async (croppedFile) => {
    try {
      setPictureLoading(true)
      setShowCropModal(false)
      const maxWidth =  aspectRatio ? parseInt(1080*aspectRatio): 1080; 
      const maxHeight =  1080; 
      const { croppedImage, imageSrc, croppedImageUrl } = croppedFile
      const resizedImage = await resizeImageFile(croppedImage,{quality:60,maxWidth,maxHeight});
      const uploadedPictureData = await uploadCroppedImage(resizedImage)
      setPictureLoading(false)
      if(onPictureChange && uploadedPictureData)
        onPictureChange(uploadedPictureData)
    } catch (err) {
      console.log("image error",err)
    }
    
  }

  useEffect(() => {
    if (currentPictureUrl) {
      setImagePreviewUrl(currentPictureUrl);
    }
  }, [currentPictureUrl]);

  useEffect(
    () => {
      localStorage.removeItem(localImageName);
    },
    []
  )


  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." style={{height:150}}/>
      </div>
      <div className='sweet-loading'>
        <BeatLoader
          sizeUnit={"px"}
          size={10}
          color={roseColor[0]}
          loading={pictureLoading}
        />
      </div>
      <div>
        {selectedImage === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Upload image"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Remove
            </Button>
          </span>
        )}
      </div>
      <Dialog
        maxWidth='md'
        fullWidth
        scroll='body'
        open={showCropModal}
        disableEscapeKeyDown
      >
        <div style={{ display: 'flex',justifyContent:'space-between'}}>
          <DialogTitle id="custom-modal-label">Crop</DialogTitle>
          <DialogActions>
            <IconButton onClick={handleCropDialog}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </div>
        <DialogContent>
          <CustomCropStyled
            confirmCrop={onConfirmCrop}
            selectedImageSrc={selectedImage}
            aspectRatio={aspectRatio}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
