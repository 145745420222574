import {
  FETCH_LOCATION_GROUP_LOADING,
  FETCH_LOCATION_GROUP_SUCCESS,
  FETCH_LOCATION_GROUP_ERROR,
  FETCH_LOCATION_GROUP_DETAILS,
} from "actions/locationGroups";


const initialState = {
  loading: false,
  list: [],
  fetchedLocationGroup: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION_GROUP_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_LOCATION_GROUP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_LOCATION_GROUP_DETAILS:
      return {
        ...state,
        fetchedLocationGroup: action.payload,
      };
    case FETCH_LOCATION_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;